import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Alert = makeShortcode("Alert");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`OpenSSH and SSHD have supported SSH certificate login for almost ten years. In this tutorial, you'll learn how to use SSH certificates (for hosts & users) generated by open source `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` using the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ssh/"
      }}><inlineCode parentName="a">{`step ssh`}</inlineCode></a>{` sub-command.`}</p>
    <h2>{`About this tutorial`}</h2>
    <ul>
      <li parentName="ul">{`Learn how to configure OpenSSH and SSHD for certificate authentication using `}<inlineCode parentName="li">{`step-ca`}</inlineCode></li>
      <li parentName="ul">{`Examples include copy/paste code blocks and pre-generated PKI. `}</li>
      <li parentName="ul">{`When complete, you will be able to use OpenSSH and SSHD for SSH certificate login with a private SSH certificate authority. `}</li>
      <li parentName="ul">{`Estimated effort: Reading time ~10 mins, Lab time ~60 to 180 mins. `}</li>
    </ul>
    <Alert severity="info" mdxType="Alert">
  <div>
    If you run into any issues please let us know <Link href="https://github.com/smallstep/certificates/discussions" mdxType="Link">in GitHub Discussions</Link>.
  </div>
    </Alert>
    <h2>{`Requirements`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Open Source -`}</strong>{` This tutorial assumes you have initialized and started up a `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{`instance using the steps in `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/getting-started"
        }}>{`Getting Started`}</a>{`.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://smallstep.com/certificate-manager"
          }}>{`Smallstep Certificate Manager`}</a>{` -`}</strong>{` Please contact `}<a parentName="li" {...{
          "href": "mailto:support@smallstep.com"
        }}>{`Smallstep Customer Success`}</a>{` if you would like to activate SSH certificates on your Certificate Manager account.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.vagrantup.com/docs/installation"
        }}>{`Vagrant`}</a></li>
    </ul>
    <h2>{`Overview`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#setup-vm"
        }}>{`Setup VM`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#configure-ssh-client-to-accept-host-certs"
        }}>{`Configure ssh client to accept host certs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#configure-sshd-to-accept-user-certs"
        }}>{`Configure sshd to accept user certs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#login-to-vm-via-ssh-user-cert"
        }}>{`Login to VM via SSH user cert`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#generate-ssh-host-certificates"
        }}>{`Generate ssh host certificates`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#generate-your-own-pki-for-step-ca"
        }}>{`Generate your own PKI for step-ca`}</a></li>
    </ul>
    <p>{`You can find all of the code in the example below here: `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/step-ssh-example"
      }}>{`https://github.com/smallstep/step-ssh-example`}</a></p>
    <p>{`The code in this repo comes with a pre-generated PKI. You will need `}<inlineCode parentName="p">{`step v0.13.3+`}</inlineCode>{` and Vagrant, plus a provider like `}<a parentName="p" {...{
        "href": "https://www.virtualbox.org/"
      }}>{`VirtualBox`}</a>{` installed locally.`}</p>
    <h2>{`Setup VM`}</h2>
    <p>{`You're going to run a CA in your local environment and we'll use SSH to connect
to a Vagrant VM (representing a remote host) that has `}<inlineCode parentName="p">{`sshd`}</inlineCode>{` pre-configured to
accept SSH user certificates signed by our CA.`}</p>
    <p>{`With Vagrant installed, run the following commands inside the repo:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ vagrant up
Bringing machine 'testhost' up with 'virtualbox' provider...
==> testhost: Importing base box 'ubuntu/bionic64'...
    [...]
==> testhost: Preparing network interfaces based on configuration...
    testhost: Adapter 1: nat
    testhost: Adapter 2: hostonly
==> testhost: Forwarding ports...
    testhost: 22 (guest) => 2222 (host) (adapter 1)
==> testhost: Running 'pre-boot' VM customizations...
==> testhost: Booting VM...
==> testhost: Waiting for machine to boot. This may take a few minutes...
    testhost: SSH address: 127.0.0.1:2222
    testhost: SSH username: vagrant
    testhost: SSH auth method: private key
    testhost: VirtualBox Version: 6.0
    [...]
==> testhost: Setting hostname...
==> testhost: Configuring and enabling network interfaces...
==> testhost: Mounting shared folders...
    testhost: /keys => /Users/sourishkrout/dev/src/smallstep/code/src/github.com/smallstep/step-examples/ssh-example/keys
    testhost: /vagrant => /Users/sourishkrout/dev/src/smallstep/code/src/github.com/smallstep/step-examples/ssh-example
==> testhost: Running provisioner: shell...
    testhost: Running: inline script
    testhost: Add following line to your local hosts ~/.ssh/known_hosts file to accept host certs
    testhost: @cert-authority * ecdsa-sha2-nistp256 AAAAE2VjZHNhLXNoYTItbmlzdHAyNTYAAAAIbmlzdHAyNTYAAABBBJJM+jkIdieQvdPb8DwnfnJudEc9PgVBqLDWHKgvqoIiMXhuIyGstQ9ULOBMdJkqxMjkRTFZp1iFvIk+iU6hwTA=
    testhost: Add a /etc/hosts file entry testhost to resolve to 192.168.0.101
    testhost: Check out README.md to learn how to grab user ssh certs to log into testhost
`}</code></pre>
    <h2>{`Configure ssh client to accept host certs`}</h2>
    <p>{`Go ahead and follow the instructions printed by Vagrant. This will enable your local SSH client to accept SSH host certificates (signed by the root SSH host private key). The following command will append the SSH host CA key (root SSH host public key corresponding to the root SSH host private key) to your local `}<em parentName="p">{`known_hosts`}</em>{` file:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ echo "@cert-authority * ecdsa-sha2-nistp256 AAAAE2VjZHNhLXNoYTItbmlzdHAyNTYAAAAIbmlzdHAyNTYAAABBBJJM+jkIdieQvdPb8DwnfnJudEc9PgVBqLDWHKgvqoIiMXhuIyGstQ9ULOBMdJkqxMjkRTFZp1iFvIk+iU6hwTA=" >> ~/.ssh/known_hosts
`}</code></pre>
    <p>{`You can also find the root SSH host CA key stored at `}<em parentName="p">{`step/certs/ssh_host_key.pub`}</em>{` in this repo.`}</p>
    <p>{`SSH certificates bind names to public keys. This SSH host certificate has the
identity `}<em parentName="p">{`testhost`}</em>{` which is why the following entry must be added to the local
`}<em parentName="p">{`/etc/hosts`}</em>{` file on the VM:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ tail -n 1 /etc/hosts
192.168.0.101   testhost
`}</code></pre>
    <h2>{`Configure sshd to accept user certs`}</h2>
    <p>{`Vagrant has already configured `}<inlineCode parentName="p">{`sshd`}</inlineCode>{` on `}<em parentName="p">{`testhost`}</em>{`, the VM generated by Vagrant. Please note that for demo purposes the PKI is shared with the VM using a shared directory mount. Below you can see the relevant lines from the `}<em parentName="p">{`testhost`}</em>{` VM's `}<inlineCode parentName="p">{`sshd_config`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ tail -n 5 /etc/ssh/sshd_config
#       PermitTTY no
#       ForceCommand cvs server
TrustedUserCAKeys /keys/ssh_user_key.pub
HostKey /keys/ssh_host_ecdsa_key
HostCertificate /keys/ssh_host_ecdsa_key-cert.pub
`}</code></pre>
    <ul>
      <li parentName="ul"><strong parentName="li">{`TrustUserCAKeys`}</strong>{`: The root SSH user public key used to verify SSH user certificates.`}</li>
      <li parentName="ul"><strong parentName="li">{`HostKey`}</strong>{`: The SSH private key specific to this host.`}</li>
      <li parentName="ul"><strong parentName="li">{`HostCertificate`}</strong>{`: The SSH public certificate that uniquely identifies this host (signed by the root SSH host private key).`}</li>
    </ul>
    <h2>{`Login to VM via SSH user cert`}</h2>
    <p>{`A valid user certificate is required to log into the `}<em parentName="p">{`testhost`}</em>{` VM. Using `}<inlineCode parentName="p">{`step`}</inlineCode>{`, you can authenticate with your SSH-enabled CA and fetch a new SSH certificate.`}</p>
    <p>{`In one terminal window, run the following command to startup your CA (password is `}<inlineCode parentName="p">{`password`}</inlineCode>{`):`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ export STEPPATH=pwd/step
$ step-ca step/config/ca.json
Please enter the password to decrypt step/secrets/intermediate_ca_key: password
Please enter the password to decrypt step/secrets/ssh_host_key: password
Please enter the password to decrypt step/secrets/ssh_user_key: password
2019/09/11 22:59:01 Serving HTTPS on :443 ...
`}</code></pre>
    <p>{`In another terminal window run:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ export STEPPATH=pwd/step
$ step ssh certificate testuser testuser_ecdsa --ca-url https://localhost --root step/certs/root_ca.crt
✔ Provisioner: admin (JWK) [kid: ux6AhkfzgclpI65xJeGHzNqHCmdCl0-nWO8YqF1mcn0]
✔ Please enter the password to decrypt the provisioner key: password
✔ CA: https://localhost
Please enter the password to encrypt the private key: your-own-password
✔ Private Key: testuser_ecdsa
✔ Public Key: testuser_ecdsa.pub
✔ Certificate: testuser_ecdsa-cert.pub
✔ SSH Agent: yes
`}</code></pre>
    <p><inlineCode parentName="p">{`step-ca`}</inlineCode>{` enforces authentication for all certificate requests and uses the
concept of `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners"
      }}>{`provisioners`}</a>{` to carry
out this enforcement. Provisioners are configured in `}<em parentName="p">{`step/config/ca.json`}</em>{`.
Authenticating as one of the sanctioned provisioners indicates to `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`
that you have the right to provision new SSH certificates.`}</p>
    <p>{`In the above invocation of `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ssh/certificate"
      }}><inlineCode parentName="a">{`step ssh certificate`}</inlineCode></a>{`, you have authenticated your
request using a JWK provisioner which requires a password to decrypt a private
key. There are a handful of supported provisioners, each with their own
authentication methods. The OIDC provisioner is particularly interesting for
SSH user certificates because it enables Single Sign-On SSH.`}</p>
    <p><a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli/reference/ssh/certificate"
      }}><inlineCode parentName="a">{`step ssh certificate`}</inlineCode></a>{` adds the new SSH user certificate to your local `}<em parentName="p">{`ssh
agent`}</em>{`. The default lifetime of an SSH certificate from `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` is 16 hours. The
lifetime can be configured using command line options.`}</p>
    <p>{`Run `}<inlineCode parentName="p">{`step ssh certificate -h`}</inlineCode>{` in `}<inlineCode parentName="p">{`step`}</inlineCode>{` for documentation and examples).`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ ssh-add -l
256 SHA256:xt5VeMEG8uf+SBlddauylJHv9+Bl0E6H+46AV94+Its testuser (ECDSA-CERT)

$ ssh testuser@testhost
Welcome to Ubuntu 18.04.3 LTS (GNU/Linux 4.15.0-55-generic x86_64)

[...]
`}</code></pre>
    <p>{`As you can see the `}<em parentName="p">{`testhost`}</em>{` VM will welcome you with a matching `}<em parentName="p">{`testuser@testhost`}</em>{` prompt.`}</p>
    <p>{`Learn how to use OAuth OIDC providers like G Suite or Instance Identity Documents to bootstrap SSH host and user certificates in the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-cli"
      }}><inlineCode parentName="a">{`step`}</inlineCode>{` reference`}</a>{`.`}</p>
    <h2>{`Generate ssh host certificates`}</h2>
    <p>{`This example repo includes a pre-generated SSH host certificate and key. To
replace it, or generate SSH certificates for other hosts, run the following
command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ssh certificate --host --principal testhost --principal testhost.internal testhost ssh_host_ecdsa_key
`}</code></pre>
    <p>{`Where `}<inlineCode parentName="p">{`--principal`}</inlineCode>{` identifies the hostname(s), ideally FQDNs, for the machine. For a single principal you can short cut the command to:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`step ssh certificate --host testhost ssh_host_ecdsa_key
`}</code></pre>
    <h2>{`Generate your own PKI for step-ca`}</h2>
    <p>{`We recommend using your own PKI for usage outside of this example. You can
initialize your `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` with both X509 and SSH certificate authorities using the
following command:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ export STEPPATH=/tmp/mystep
$ step ca init --ssh
✔ What would you like to name your new PKI? (e.g. Smallstep): Smallstep
✔ What DNS names or IP addresses would you like to add to your new CA? (e.g. ca.smallstep.com[,1.1.1.1,etc.]): localhost
✔ What address will your new CA listen at? (e.g. :443): :443
✔ What would you like to name the first provisioner for your new CA? (e.g. you@smallstep.com): admin
✔ What do you want your password to be? [leave empty and we will generate one]:

Generating root certificate...
all done!

Generating intermediate certificate...

Generating user and host SSH certificate signing keys...
all done!

✔ Root certificate: /tmp/mystep/certs/root_ca.crt
✔ Root private key: /tmp/mystep/secrets/root_ca_key
✔ Root fingerprint: d601c93a6256080e42cf02087fdc737f1429226ada6c040bac6494332e01527e
✔ Intermediate certificate: /tmp/mystep/certs/intermediate_ca.crt
✔ Intermediate private key: /tmp/mystep/secrets/intermediate_ca_key
✔ SSH user root certificate: /tmp/mystep/certs/ssh_user_key.pub
✔ SSH user root private key: /tmp/mystep/secrets/ssh_user_key
✔ SSH host root certificate: /tmp/mystep/certs/ssh_host_key.pub
✔ SSH host root private key: /tmp/mystep/secrets/ssh_host_key
✔ Default configuration: /tmp/mystep/config/defaults.json
✔ Certificate Authority configuration: /tmp/mystep/config/ca.json\\n
Your PKI is ready to go. To generate certificates for individual services see 'step help ca'.
`}</code></pre>
    <p>{`Now you can launch your instance of `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` with your own PKI like so:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step-ca $(step path)/config/ca.json
Please enter the password to decrypt /tmp/mystep/secrets/intermediate_ca_key:
Please enter the password to decrypt /tmp/mystep/secrets/ssh_host_key:
Please enter the password to decrypt /tmp/mystep/secrets/ssh_user_key:
2019/09/11 23:34:13 Serving HTTPS on :443 ...
`}</code></pre>
    <p>{`Please note that after you regenerate `}<em parentName="p">{`ssh_host_key.pub`}</em>{` and `}<em parentName="p">{`ssh_user_key.pub`}</em>{`, you will have to reconfigure `}<inlineCode parentName="p">{`ssh`}</inlineCode>{` and `}<inlineCode parentName="p">{`sshd`}</inlineCode>{` for clients and hosts to accept the new CA keys. Check out `}<a parentName="p" {...{
        "href": "https://github.com/smallstep/step-ssh-example/blob/master/host-bootstrap.sh"
      }}>{`this host bootstrapping script`}</a>{` for configuration examples.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      